* {
  margin: 0;
  --brand-primary-color: #0099ff;
  --brand-primary-dark: #002776;
  --brand-secondary-color: #ff5490;
  --brand-secondary-text: #a3abbd;
}

body {
  margin: 0;
  font-family: "Montserrat", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../images/background.svg") repeat-x fixed center bottom;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.send-message-logo {
  text-align: center;
}

.send-message-header {
  color: var(--brand-primary-dark);
  font-size: 32px;
  font-weight: 600;
  width: 100px;
  margin-bottom: 10px;
}

.confirm-image {
  width: 100%;
}

.message-sent-header,
.tag-not-found-header {
  color: var(--brand-primary-dark);
  font-size: 32px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 10px;
}

.tag-not-found-header {
  color: var(--brand-secondary-color);
}

label[for="form-message"]::after {
  content: "*";
  color: red;
}

.message-sub-header {
  color: var(--brand-secondary-text);
  font-size: 16px;
  margin-bottom: 65px;
}

.tertiary-text {
  color: var(--brand-secondary-text);
  font-size: 16px;
}

.download-our-app-header {
  color: var(--brand-primary-dark);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.download-our-app-header > span {
  color: var(--brand-primary-color);
}

.made-with-love {
  color: var(--brand-secondary-text);
  font-size: 16px;
  margin: 1rem 0;
  text-align: center;
  width: 100%;
}

.scan-page .made-with-love {
  display: none;
}

.send-message-label {
  margin-top: 30px;
}

.show-number-button {
  margin-left: 5px;
}

.loading {
  margin-right: 0.3rem;
  color: #e9e9e9da;
}

.store-buttons {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 0 1rem;
}

.store-buttons > a > img {
  max-width: 85%;
}

@media (min-width: 600px) {
  .content {
    margin: 50px auto auto;
    margin-top: 0;
    padding-top: 50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }

  .message-container {
    width: 560px;
    border-radius: 24px;
    box-shadow: 0 0 20px 10px rgb(0 155 253 / 10%);
    margin-top: 50px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    background-color: white;
  }

  .inner-message-container {
    padding: 55px 75px 0;
  }
}

@media (max-width: 600px) {
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 50px auto;
  }

  .message-container {
    background-color: #fff;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .inner-message-container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}
